import React from 'react';
import './Companies.css';

function Companies() {
  return (
    <section className='c-wrapper'>
        <div className='paddings innerwidth flexCenter c-container'>
            <img src="./equinix.png" alt=""/>
            <img src="./prologis.png" alt=""/>
            <img src="./realty.png" alt=""/>
            <img src="./tower.png" alt=""/>
        </div>
    </section>

  )
}

export default Companies
